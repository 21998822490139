import { createContext, useContext, FC, ReactElement, useState, useEffect } from "react";
import { axios } from "../api/authenticated-axios";

type IProp = {
    children: ReactElement;
};

interface IOnBoardingProps {
    breeds: any
    agents: any
    clubs: any
    tests: any
    breeder: any
    setBreeder: any
}

export const OnBoarding = createContext({} as IOnBoardingProps);

export const useOnBoardingContext = ()=> useContext(OnBoarding);

export const OnBoardingContextProvider: FC<IProp> = ({ children }) => {
    const [breeder, setBreeder] = useState<boolean | null>(null);
    const [breeds, setBreeds] = useState({data: []});
    const [agents, setAgents] = useState({data: []});
    const [clubs, setClubs] = useState({data: []});
    const [tests, setTests] = useState({data: []});

    useEffect(()=> {
        fetchInitData();
    }, []);

    const fetchInitData = async ()=> {
        const promiseArray = [
            axios.get("/register/getbreeds"),
            axios.get("/register/getagriaagents"),
            axios.get("/register/getclubs"),
            axios.get("/register/getTests")
        ];
        const [breeds, agents, clubs, tests] = await Promise.all<any>(promiseArray);
        
        setBreeds(breeds);
        setAgents(agents);
        setClubs(clubs);
        setTests(tests);
    };

    const defaultContext = {
        breeds, agents,
        clubs, tests,
        breeder, setBreeder
    };
    return (
        <OnBoarding.Provider value={defaultContext}>
            {children}
        </OnBoarding.Provider>
    );
};