import "rc-slider/assets/index.css";
import Fade from "react-reveal/Fade";
import { useQuery } from "react-query";
import useAuth0 from "../hooks/useAuth0";
import Button from "../components/Button";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useIsMobile } from "../hooks/useIsMobile";
import { axios } from "../api/authenticated-axios";
import css from "../styles/BreederOverview.module.scss";
import { useInView } from "react-intersection-observer";
import {
  IApiContentResponsePagedViewModel,
} from "../api/umbracoApi";
import {
  useUmbracoContentItemQuery,
  useUmbracoContentQuery,
} from "../hooks/useUmbracoQuery";
import {
  Avatar,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import LoadingScreen from "../components/LoadingScreen";
import { Helmet } from "react-helmet-async";
import VerifiedBadge from "../components/VerifiedBadge";

const BreederOverview = () => {
  const { isAuthenticated } = useAuth0();
  const { ref, inView } = useInView();
  const [pageNumber, setPageNumber] = useState(1);
  const [isFetchingNextPage, setIsFetchingNextPage] = useState(false);
  const [hasNextPage, setHasNextPage] = useState<any>(Number);
  const [allBreeders, setAllBreeders] = useState<any>([]);
  const [breed, setBreed] = useState("");
  const [kennel, setKennel] = useState("");

  const { isLoading, isFetching } = useQuery(
    ["DogBreederSearch", pageNumber, breed, kennel],
    () => {
      const params: any = {
        PageNumber: pageNumber,
        SearchText: kennel,
        BreedType: breed == "Alle" ? "" : breed,
      };

      return axios.post("/dogbreeder/search", params).then((res) => res.data);
    },
    {
      onSuccess: (data) => {
        if (pageNumber == 1) {
          setAllBreeders(() => [...data.items]);
        } else {
          setAllBreeders((prev: any) => [...prev, ...data.items]);
        }
        setHasNextPage(data?.totalPages > pageNumber);
      },
    }
  );

  const { data: DogBreeds } =
    useUmbracoContentQuery<IApiContentResponsePagedViewModel>({
      filter: ["contentType:dogBreedItem"],
    });

  const { data: contents } =
    useUmbracoContentItemQuery<any>("oppdrettere");

  const { isMobile } = useIsMobile();
  useEffect(() => {
    window.analytics.page("Oppdretteroversikt");
  }, []);
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const history = useHistory();

  const fetchNextPage = () => {
    if (hasNextPage && !isFetchingNextPage) {
      setIsFetchingNextPage(true);
      setPageNumber((prev) => prev + 1);
      setIsFetchingNextPage(false);
    }
  };

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);
  function urlFor(source: any) {
    return baseUrl + source;
  }

  return (
    <div className={css.BreederOverview}>
      <Helmet>
      <title>{contents?.properties?.metaTitle ? contents?.properties?.metaTitle:" POND - Kilden til ditt nye familiemedlem"}</title>
        <meta name="description" content={contents?.properties?.metaDescription ? contents?.properties?.metaDescription : "Plattform for ansvarlig kjøp og salg av hund."} />
        <meta property="og:title" content={contents?.properties?.metaTitle ? contents?.properties?.metaTitle : " POND - Kilden til ditt nye familiemedlem"} />
        <meta property="og:type" content={contents?.properties?.metaKeywords ? contents?.properties?.metaKeyword : "website"} />
        <meta
          property="og:description"
          content={contents?.properties?.metaDescription ? contents?.properties?.metaDescription : "Plattform for ansvarlig kjøp og salg av hund."}
        />
        <link rel="canonical" href={contents?.properties?.canonicalUrl ? contents?.properties?.canonicalUrl:"https://pond-api.codify.no"}/>
        {contents?.properties?.ogImage && (
          <meta
            property="og:image"
            content={urlFor(contents?.properties?.ogImage[0].url).toString()}
          />)}
        </Helmet>
      <div className={css.intro}>
        <h1>{contents?.properties?.title}</h1>
        <p>{contents?.properties?.description}</p>
      </div>
      <div className={css.filterContainer}>
        <FormControl
          size="small"
          sx={isMobile ? { width: "48%" } : { width: "20%" }}
        >
          <TextField
            id="breeder-search"
            value={kennel}
            type="text"
            size="small"
            label="Søk etter kennel"
            onChange={(e) => setKennel(e.target.value)}
            disabled={!isAuthenticated}
          />
        </FormControl>
        <FormControl
          size="small"
          sx={isMobile ? { width: "48%" } : { width: "20%" }}
          disabled={!isAuthenticated}
        >
          <InputLabel id="breed-select-label">Rase</InputLabel>
          <Select
            labelId="breed-select-label"
            id="breed-select"
            value={breed}
            label="Rase"
            autoWidth
            onChange={(e) => {
              setPageNumber(1);
              setBreed(e.target.value);
            }}
          >
            <MenuItem value={"Alle"}>Alle</MenuItem>
            {DogBreeds?.items?.sort().map((item) => (
              <MenuItem key={item.id} value={item.name || ""}>
                {item.name || ""}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {!isAuthenticated && (
        <div className={css.notLoggedIn}>
          <h3>{contents?.properties?.loginHeading}</h3>
          <Button
            label="Kom i gang"
            onClick={() => {
              history.push(
                `${
                  contents?.properties?.loginRedirect &&
                  contents?.properties?.loginRedirect[0].route &&
                  contents?.properties?.loginRedirect[0].route.path
                }`
              );
            }}
          />
        </div>
      )}
      {isFetching && pageNumber<=1 && <LoadingScreen />}
      <div
        className={css.grid}
        style={isAuthenticated ? { paddingTop: "10rem" } : undefined}
      >
        {allBreeders.map((breeder: any, index: number) => (
          <Fade bottom key={`${breeder.breederId}-${index}`}>
            <div
              className={css.card}
              onClick={() => {
                if (isAuthenticated) {
                  history.push(`/oppdretter/${breeder.breederId}`);
                }
              }}
            >
              <div className={css.banner}>
                {breeder.bannerImageUrl && (
                  <img src={urlFor(breeder.bannerImageUrl+"?width=410")} alt={breeder.firstName} />
                )}
              </div>
              <Avatar
                className={css.avatar}
                src={urlFor(breeder.avatar+"?width=148&height=148") ?? "/images/default-avatar.png"}
              />
              <div className={css.breederBadge}>
              {breeder.isVerified == 1 && (
                  <VerifiedBadge
                    desktopHeight="72px"
                    desktopWidth="72px"
                    mobileWidth="39px"
                    mobileHeight="42px"
                  />
                )}
              </div>
              <div className={css.kennel}>{breeder.kennelName}</div>
              <div className={css.city}>
                {breeder.city?.trim() || "Ukjent"}, Norge
              </div>
            </div>
          </Fade>
        ))}
      </div>
     
      {!isFetching && allBreeders?.length <= 0 && (
        <div className={css.noResult}>
          <Fade bottom>
            <img src="/images/ouch.svg" alt="Ikke funnet illustrasjon" />
            <h2>Fant ingen oppdrettere</h2>
          </Fade>
        </div>
      )}
      {isAuthenticated && (
        <div ref={ref}>
            {isFetchingNextPage || hasNextPage ? (
               <LoadingScreen />
            ) : null
          }
        </div>
      )}
    </div>
  );
};
export default BreederOverview;
