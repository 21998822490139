import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { DogAd, DogParent, Puppy, useGetAdQuery } from "../api/graphql.gen-old";
import css from "../styles/Ad.module.scss";
import IncludedCard from "../components/Profile/IncludedCard";
import {
  Avatar,
  Chip,
  Collapse,
  SwipeableDrawer,
  Tab,
  Tabs,
} from "@mui/material";

import LoadingScreen from "../components/LoadingScreen";
import Modal from "react-bootstrap/Modal";
import {
  translateHD,
  translateAD,
  translatePatella,
} from "../utils/healthTranslations";
import { useIsMobile } from "../hooks/useIsMobile";
import { format } from "date-fns";
import PuppyModalContent from "../components/Profile/PuppyModalContent";
import DogModalContent from "../components/Profile/DogModalContent";
import Button from "../components/Button";
import CreateUserDialog from "../components/CreateUserDialog";
import {
  DogAdDto,
  DogParentDto,
  PuppyDto,
  useDogAdDetailQueryQuery,
  useDogAdQueryQuery,
} from "../api/graphql.gen";
import useAuth0 from "../hooks/useAuth0";
import { useUmbracoContentQuery } from "../hooks/useUmbracoQuery";
import SectionsData from "../components/SectionData";
import {
  ClubItemContentResponseModel,
  IApiContentResponsePagedViewModel,
} from "../api/umbracoApi";
import DogModal from "../components/Profile/DogDetailModal";

interface RouteParams {
  adId: string;
}
const Ad: React.FC = () => {
  const [visibleIndex, setVisibleIndex] = useState<number | null>(null);

  const handleToggle = useCallback(
    (id: number) => {
      setVisibleIndex((prevIndex) => (prevIndex === id ? null : id));
    },
    [setVisibleIndex]
  );
  const { adId } = useParams<RouteParams>();

  const { data: data, isLoading } = useDogAdDetailQueryQuery({
    adId: Number(adId),
  });
  // const { data, isLoading } = useGetAdQuery({ adId: Number(adId) });
  const ad = data?.dogAdQuery?.ad;
  const history = useHistory();
  const { isMobile } = useIsMobile();
  const { isAuthenticated, user: authUser } = useAuth0();
  const [adVisible, setAdVisible] = useState(false);
  const [aboutVisible, setAboutVisible] = useState(false);
  const [parentVisible, setParentVisible] = useState(false);
  const [puppyVisible, setPuppyVisible] = useState(false);
  const [kennelVisible, setKennelVisible] = useState(false);
  const [includedVisible, setIncludedVisible] = useState(false);
  const [parentModal, setParentModal] = useState(false);
  const [tab, setTab] = useState("1");
  const [puppyModal, setPuppyModal] = useState(false);
  const [dogModal, setDogModal] = useState(false);
  const [showCreateUser, setShowCreateUser] = useState(false);
  const [selectedParentMobile, setSelectedParentMobile] =
    useState<DogParentDto>();

  const adRef = useRef<HTMLDivElement>(null);
  const aboutRef = useRef<HTMLDivElement>(null);
  const puppiesRef = useRef<HTMLDivElement>(null);
  const breederRef = useRef<HTMLDivElement>(null);
  const parentsRef = useRef<HTMLDivElement>(null);
  const includedRef = useRef<HTMLDivElement>(null);

  const executeScroll = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  };
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  function urlFor(source: any) {
    return baseUrl + source;
  }
  const { data: clubItem } =
    useUmbracoContentQuery<IApiContentResponsePagedViewModel>({
      filter: ["contentType:clubItem"],
    });
  const clubs = ad?.member.kennel?.clubsList;
  useEffect(() => {
    if (!isLoading && ad?.adType !== "DELETED" && ad?.adType !== "AVAILABLE") {
      history.push(`/oppdretter/${ad?.member.memberId}`);
    }
  }, [ad, isLoading]);

  if (isLoading) {
    return <LoadingScreen />;
  }
  const adImage =
    ad?.adImage == "/images/placeholder-dog.png" ||
    ad?.adImage == "" ||
    !ad?.adImage
      ? ""
      : JSON.parse(ad?.adImage ?? "");

  function puppyAvailability(breed: string) {
    switch (breed) {
      case "AVAILABLE":
        return "Tilgjengelig";
        break;

      case "RESERVED":
        return "Reservert";
        break;
      case "SOLD":
        return "Solgt";
        break;
      default:
        return "Tilgjengelighet ikke spesifisert";
        break;
    }
  }

  if (isMobile) {
    return (
      <div className={css.mobile}>
        <div className={css.section} ref={adRef}>
          <div className={css.adCard}>
            {ad?.adType === "DELETED" && (
              <Chip
                label="Solgt/Inaktiv"
                color="error"
                variant="outlined"
                sx={{ marginBottom: 1 }}
              />
            )}
            <div>
              {adImage && adImage.url && (
                <img
                  src={urlFor(adImage.url + "?width=410&height=410")}
                  alt={ad?.title}
                />
              )}
            </div>
            <div className={css.flex}>
              <div>
                <div>
                  <div className={css.title}>{ad?.title}</div>
                  <div className={css.breed}>{ad?.breed}</div>
                </div>
              </div>
              <div className={css.titleAndButton}>
                <div className={css.title}>{ad?.price} kr</div>
                {!authUser.breeder && (
                  <Button
                    style={{ width: "160px" }}
                    label="Send søknad"
                    onClick={() =>
                      isAuthenticated
                        ? history.push(`/send-soknad/${ad?.member?.memberId}`)
                        : setShowCreateUser(true)
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={css.collapseContainer}>
          <div
            className={css.mobileSection}
            onClick={() => setAdVisible(!adVisible)}
          >
            <div>Info</div>
            <img
              src={
                adVisible ? "/images/icons/close.svg" : "/images/icons/open.svg"
              }
              alt="Åpne ikon"
            />
          </div>
          <Collapse
            in={adVisible}
            timeout="auto"
            className={adVisible ? css.collapse : ""}
          >
            <div>
              <p>
                <b>Antall valper:</b> {ad?.puppies?.length}
              </p>
              <p>
                <b>Født:</b> {format(new Date(ad?.birthDate), "dd.MM.yyyy")}
              </p>
              <p>
                <b>Leveringsklar:</b>{" "}
                {format(new Date(ad?.readyForDelivery), "dd.MM.yyyy")}
              </p>
            </div>
          </Collapse>
        </div>
        <div className={css.collapseContainer}>
          <div
            className={css.mobileSection}
            onClick={() => setAboutVisible(!aboutVisible)}
          >
            <div>Om kullet</div>
            <img
              src={
                aboutVisible
                  ? "/images/icons/close.svg"
                  : "/images/icons/open.svg"
              }
              alt="Åpne ikon"
            />
          </div>
          <Collapse
            in={aboutVisible}
            timeout="auto"
            className={aboutVisible ? css.collapse : ""}
          >
            <div>
              <p>{ad?.expLitter}</p>
            </div>
          </Collapse>
        </div>
        <div className={css.collapseContainer}>
          <div
            className={css.mobileSection}
            onClick={() => setPuppyVisible(!puppyVisible)}
          >
            <div>Valper</div>
            <img
              src={
                puppyVisible
                  ? "/images/icons/close.svg"
                  : "/images/icons/open.svg"
              }
              alt="Åpne ikon"
            />
          </div>
          <Collapse
            in={puppyVisible}
            timeout="auto"
            className={puppyVisible ? css.collapse : ""}
          >
            <div className={css.puppyGrid}>
              {ad?.puppies?.map((puppy, index) => {
                const puppyImage =
                  puppy.image == "/images/placeholder-dog.png" ||
                  puppy.image == "" ||
                  puppy.image == undefined ||
                  !puppy.image
                    ? ""
                    : JSON.parse(puppy.image ?? "");
                return (
                  <div
                    key={puppy.id} //onClick={() => setPuppyModal(true)}
                  >
                    <img
                      src={
                        puppyImage && puppyImage.url
                          ? urlFor(puppyImage.url + "?width=170&height=170")
                          : "/images/placeholder-dog.png"
                      }
                      alt="Bilde av valp"
                    />
                    <div className={css.title}>
                      {puppy.name.length ? puppy.name : `Valp ${index + 1}`}
                    </div>
                    <div className={css.breed}>
                      {puppy.availability.length
                        ? puppyAvailability(puppy.availability)
                        : "Tilgjengelighet ikke spesifisert"}
                    </div>
                    <div className={css.breed}>
                      {puppy.gender === "male" ? "Hannhund" : "Tispe"}
                    </div>
                    <div className={css.breed}>
                      {puppy.color.length ? puppy.color : "Farge ikke oppgitt"}
                    </div>
                  </div>
                );
              })}
            </div>
          </Collapse>
        </div>
        <div className={css.collapseContainer}>
          <div
            className={css.mobileSection}
            onClick={() => setParentVisible(!parentVisible)}
          >
            <div>Foreldre</div>
            <img
              src={
                parentVisible
                  ? "/images/icons/close.svg"
                  : "/images/icons/open.svg"
              }
              alt="Åpne ikon"
            />
          </div>
          <Collapse
            in={parentVisible}
            timeout="auto"
            className={parentVisible ? css.collapse : ""}
          >
            <div className={css.puppyGrid}>
              {ad?.parents &&
                ad?.parents?.map((parent) => {
                  const parentImage =
                    parent.images == "/images/placeholder-dog.png" ||
                    parent.images == "" ||
                    !parent.images
                      ? ""
                      : JSON.parse(parent.images ?? "");
                  return (
                    <div
                      key={parent.id}
                      onClick={() => {
                        setDogModal(true);
                        setSelectedParentMobile(parent as DogParentDto);
                      }}
                    >
                      <img
                        src={
                          parentImage && parentImage.url
                            ? urlFor(parentImage?.url + "?width=170&height=170")
                            : "/images/placeholder-dog.png"
                        }
                        alt="Bilde av valp"
                      />
                      <div className={css.title}>{parent.name}</div>
                      <div className={css.breed}>
                        {parent.gender === "male" ? "Far" : "Mor"}
                      </div>
                    </div>
                  );
                })}
            </div>
          </Collapse>
        </div>
        <div className={css.collapseContainer}>
          <div
            className={css.mobileSection}
            onClick={() => setIncludedVisible(!includedVisible)}
          >
            <div>Inkludert med valpen</div>
            <img
              src={
                includedVisible
                  ? "/images/icons/close.svg"
                  : "/images/icons/open.svg"
              }
              alt="Åpne ikon"
            />
          </div>
          <Collapse
            in={includedVisible}
            timeout="auto"
            className={includedVisible ? css.collapse : ""}
          >
            <div className={css.includedGrid}>
              {ad?.pedigree && (
                <IncludedCard
                  title="Stamtavle"
                  imgSrc="/images/icons/profile/tree.svg"
                />
              )}
              {ad?.advising && (
                <IncludedCard
                  title="Rådgivning"
                  imgSrc="/images/icons/profile/chat.svg"
                />
              )}
              {ad?.idTag && (
                <IncludedCard
                  title="ID-Merking"
                  imgSrc="/images/icons/profile/id.svg"
                />
              )}
              {ad?.vaccinated && (
                <IncludedCard
                  title="Vaksine"
                  imgSrc="/images/icons/profile/vax.svg"
                />
              )}
              {ad?.insurance && (
                <IncludedCard
                  title="Forsikring"
                  imgSrc="/images/icons/profile/health.svg"
                />
              )}
              {ad?.wormTreatment && (
                <IncludedCard
                  title="Ormekur"
                  imgSrc="/images/icons/profile/worm.svg"
                />
              )}
              {ad?.healthCertificate && (
                <IncludedCard
                  title="Helsebok"
                  imgSrc="/images/icons/profile/health.svg"
                />
              )}
            </div>
          </Collapse>
        </div>
        <div className={css.collapseContainer}>
          <div
            className={css.mobileSection}
            onClick={() => setKennelVisible(!kennelVisible)}
          >
            <div>Bli kjent med kennel</div>
            <img
              src={
                kennelVisible
                  ? "/images/icons/close.svg"
                  : "/images/icons/open.svg"
              }
              alt="Åpne ikon"
            />
          </div>
          <Collapse
            in={kennelVisible}
            timeout="auto"
            className={kennelVisible ? css.collapse : ""}
          >
            <div>
              <div
                className={css.personInfo}
                onClick={() =>
                  history.push(`/oppdretter/${ad?.member.memberId}`)
                }
              >
                <Avatar
                  className={css.avatar}
                  src={
                    ad?.member && ad?.member.profileImage
                      ? urlFor(ad?.member.profileImage)
                      : "/images/default-avatar.png"
                  }
                />
                <div>
                  <div className={css.name}>
                    {ad?.member.firstName + " " + ad?.member.lastName}
                  </div>
                  <div className={css.city}>{ad?.member.city}, Norge</div>
                </div>
              </div>
              <p>{ad?.member.kennel?.experience}</p>
              <div className={css.textContainer}>
                <h5>Medlem av</h5>
                <div className={css.clubGrid}>
                  {clubs?.map((club) => {
                    const hasClub: ClubItemContentResponseModel | undefined =
                      clubItem?.items.find(
                        (item: any) =>
                          item?.properties?.clubName.toLowerCase() ===
                          club.toLowerCase()
                      );

                    return (
                      <div
                        className={css.clubContainer}
                        key={club}
                        onClick={() => {
                          hasClub?.properties.clubLink?.[0].url
                            ? window.open(hasClub?.properties.clubLink?.[0].url)
                            : undefined;
                        }}
                      >
                        <Avatar
                          className={css.avatar}
                          src={
                            hasClub?.properties?.clubImage?.[0]?.url
                              ? baseUrl + hasClub.properties.clubImage[0].url
                              : "/images/icons/club.svg"
                          }
                          alt="klubb ikon"
                        />
                        <div>{club}</div>
                      </div>
                    );
                  })}
                  {ad?.member.isAgriaMember && (
                    <div className={css.clubContainer}>
                      <Avatar
                        src="/images/agria-logo.png"
                        className={css.avatar}
                        alt="klubb ikon"
                      />
                      <div>Agria breeders club</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Collapse>
        </div>
        <SwipeableDrawer
          anchor={"bottom"}
          open={puppyModal}
          onClose={() => setPuppyModal(false)}
          onOpen={() => setPuppyModal(true)}
          swipeAreaWidth={100}
          sx={{ height: "calc(100% - 150px)", top: 150 }}
        >
          {ad && (
            <PuppyModalContent
              adData={ad as DogAdDto}
              puppies={ad?.puppies as PuppyDto[]}
            />
          )}
        </SwipeableDrawer>
        {/* <SwipeableDrawer
          anchor={"bottom"}
          open={dogModal}
          onClose={() => {
            setDogModal(false);
          }}
          onOpen={() => setDogModal(true)}
          swipeAreaWidth={100}
        > */}
        {selectedParentMobile && dogModal && (
          <DogModal
            show={dogModal}
            onHide={() => setDogModal(false)}
            selectedParent={selectedParentMobile}
          />
        )}
        {/* </SwipeableDrawer> */}
      </div>
    );
  }

  return (
    <div className={css.Ad}>
      <div className={css.sideBar}>
        <div className={css.menu}>
          <div onClick={() => executeScroll(adRef)}>Info</div>
          <div onClick={() => executeScroll(aboutRef)}>Om kullet</div>
          <div onClick={() => executeScroll(puppiesRef)}>Valpene</div>
          <div onClick={() => executeScroll(parentsRef)}>Foreldre</div>
          <div onClick={() => executeScroll(includedRef)}>Inkludert</div>
          <div onClick={() => executeScroll(breederRef)}>Kennel</div>
        </div>
      </div>
      <div className={css.content}>
        <div className={css.section} ref={adRef}>
          <div className={css.adCard}>
            {ad?.adType === "DELETED" && (
              <Chip
                label="Solgt/Inaktiv"
                color="error"
                variant="outlined"
                sx={{ marginBottom: 1 }}
              />
            )}
            <div>
              {adImage && adImage.url && (
                <img
                  src={urlFor(adImage.url + "?width=410&height=410")}
                  alt={ad?.title}
                />
              )}
            </div>
            <div className={css.sectionTop}>
              <div>
                <div className={css.title}>
                  {ad?.title} - {ad?.price} kr
                </div>
                <div className={css.breed}>{ad?.breed}</div>
              </div>
              <div>
                {!authUser.breeder && (
                  <Button
                    label="Send søknad"
                    onClick={() =>
                      isAuthenticated
                        ? history.push(`/send-soknad/${ad?.member?.memberId}`)
                        : setShowCreateUser(true)
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={css.section} ref={aboutRef}>
          <h6>Om kullet</h6>
          <p>{ad?.expLitter}</p>
        </div>
        <div className={css.section} ref={puppiesRef}>
          <h6>Valper</h6>
          <div className={css.puppyGrid}>
            {ad?.puppies?.map((puppy, index) => {
              const puppyImage =
                puppy.image == "/images/placeholder-dog.png" ||
                puppy.image == "" ||
                !puppy.image
                  ? ""
                  : JSON.parse(puppy.image ?? "");
              return (
                <div key={puppy.id}>
                  <img
                    src={
                      puppyImage && puppyImage.url
                        ? urlFor(puppyImage?.url + "?width=410&height=410")
                        : "/images/placeholder-dog.png"
                    }
                    alt="Bilde av valp"
                  />
                  <div className={css.title}>
                    {puppy.name.length ? puppy.name : `Valp ${index + 1}`}
                  </div>
                  <div className={css.breed}>
                    {puppy.availability.length
                      ? puppyAvailability(puppy.availability)
                      : "Tilgjengelighet ikke spesifisert"}
                  </div>
                  <div className={css.breed}>
                    {puppy.gender === "male" ? "Hannhund" : "Tispe"}
                  </div>
                  <div className={css.breed}>
                    {puppy.color.length ? puppy.color : "Farge ikke oppgitt"}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={css.section} ref={parentsRef}>
          <h6>Foreldre</h6>
          <div className={css.puppyGrid}>
            {ad?.parents?.map((parent) => {
              const parentImage =
                parent.images == "/images/placeholder-dog.png" ||
                parent.images == "" ||
                !parent.images
                  ? ""
                  : JSON.parse(parent.images ?? "");

              return (
                <div key={parent.id} onClick={() => setParentModal(true)}>
                  <img
                    src={
                      parentImage && parentImage.url
                        ? urlFor(parentImage?.url + "?width=170&height=170")
                        : "/images/placeholder-dog.png"
                    }
                    alt="Bilde av valp"
                  />
                  <div className={css.title}>{parent.name}</div>
                  <div className={css.breed}>
                    {parent.gender === "male" ? "Far" : "Mor"}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={css.section} ref={includedRef}>
          <h6>Inkludert med valpen</h6>
          <div className={css.includedGrid}>
            {ad?.pedigree && (
              <IncludedCard
                title="Stamtavle"
                imgSrc="/images/icons/profile/tree.svg"
              />
            )}
            {ad?.advising && (
              <IncludedCard
                title="Rådgivning"
                imgSrc="/images/icons/profile/chat.svg"
              />
            )}
            {ad?.idTag && (
              <IncludedCard
                title="ID-Merking"
                imgSrc="/images/icons/profile/id.svg"
              />
            )}
            {ad?.vaccinated && (
              <IncludedCard
                title="Vaksine"
                imgSrc="/images/icons/profile/vax.svg"
              />
            )}
            {ad?.insurance && (
              <IncludedCard
                title="Forsikring"
                imgSrc="/images/icons/profile/health.svg"
              />
            )}
            {ad?.wormTreatment && (
              <IncludedCard
                title="Ormekur"
                imgSrc="/images/icons/profile/worm.svg"
              />
            )}
            {ad?.healthCertificate && (
              <IncludedCard
                title="Helsebok"
                imgSrc="/images/icons/profile/health.svg"
              />
            )}
          </div>
        </div>
        <div className={`${css.section} ${css.noBorder}`} ref={breederRef}>
          <h6>Bli kjent med kennel</h6>
          <div
            className={css.personInfo}
            onClick={() => history.push(`/oppdretter/${ad?.member.memberId}`)}
          >
            <Avatar
              className={css.avatar}
              src={
                ad?.member && ad?.member.profileImage
                  ? urlFor(ad?.member.profileImage)
                  : "/images/default-avatar.png"
              }
            />
            <div>
              <div className={css.name}>
                {ad?.member.firstName + " " + ad?.member.lastName}
              </div>
              <div className={css.city}>{ad?.member.city}, Norge</div>
            </div>
          </div>
          <p>{ad?.member.kennel?.experience}</p>
          <div className={css.textContainer}>
            <h5>Medlem av</h5>
            <div className={css.clubGrid}>
              {clubs?.map((club: any) => {
                // Find the club once outside of JSX to avoid redundant calls.
                const hasClub: ClubItemContentResponseModel | undefined =
                  clubItem?.items.find(
                    (item: any) =>
                      item?.properties?.clubName.toLowerCase() ===
                      club.toLowerCase()
                  );

                return (
                  <div
                    className={css.clubContainer}
                    key={club}
                    onClick={() => {
                      hasClub?.properties.clubLink?.[0].url
                        ? window.open(hasClub?.properties.clubLink?.[0].url)
                        : undefined;
                    }}
                  >
                    <Avatar
                      className={css.avatar}
                      src={
                        hasClub?.properties?.clubImage?.[0]?.url // Use the found club's image if available
                          ? baseUrl + hasClub.properties.clubImage[0].url
                          : "/images/icons/club.svg" // Fallback if no image exists
                      }
                      alt="Club icon"
                    />
                    <div>{club}</div>
                  </div>
                );
              })}

              {ad?.member.isAgriaMember && (
                <div className={css.clubContainer}>
                  <Avatar
                    src="/images/agria-logo.png"
                    className={css.avatar}
                    alt="klubb ikon"
                  />
                  <div>Agria breeders club</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {ad?.parents && ad.parents.length > 0 && parentModal && (
        <DogModal
          show={parentModal}
          onHide={() => setParentModal(false)}
          selectedParent={ad.parents as DogParentDto[]}
        />
      )}

      <CreateUserDialog
        open={showCreateUser}
        description="Du må opprette bruker eller logge inn for å kontakte oppdretter"
        eventName="Registrer - Kontakt oppdretter"
        onClose={() => setShowCreateUser(false)}
      />
    </div>
  );
};

export default Ad;
