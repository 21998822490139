import React, { useEffect, useState } from "react";
import css from "../../styles/Info.module.scss";
import DOMPurify from "dompurify";
import { useUmbracoContentItemQuery } from "../../hooks/useUmbracoQuery";
import { TextPageContentModel } from "../../api/umbracoApi";

const TermsAndCond: React.FC = function () {
  const { data: contents } = useUmbracoContentItemQuery<TextPageContentModel>(
    "personvernaerklering"
  );
  useEffect(() => {
    window.analytics.page("Personvernerklæring");
  }, []);
  const sanitizedContent = DOMPurify.sanitize(
    contents?.properties?.pageBody?.markup ?? ""
  );
  return (
    <div className={css.infoPages}>
      <h1>{contents?.properties?.pageTitle}</h1>
      <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
    </div>
  );
};

export default TermsAndCond;
