import React, { useEffect, useState } from "react";
import css from "../../styles/FAQ.module.scss";
import { useUmbracoContentItemQuery, useUmbracoContentQuery } from "../../hooks/useUmbracoQuery";
import FaqSection from "../../components/UmbracoComponents/FAQ/FaqSection";
import { IApiContentResponsePagedViewModel } from "../../api/umbracoApi";
import { Helmet } from "react-helmet-async";

const FAQ: React.FC = function () {
  const [visibleIndex, setVisibleIndex] = useState<number | null>(null);
  const [groupedItems, setGroupedItems] = useState<any>();
  const { data: faqsPaged } =
    useUmbracoContentQuery<IApiContentResponsePagedViewModel>({
      fetch: "children:faq",
      sort: ["sortOrder:asc"],
    });
    const { data: contents } =
    useUmbracoContentItemQuery<any>("spoersmaal");
    console.log(",,,,,,  ",contents);
  useEffect(() => {
    window.analytics.page("FAQ");
  }, []);

  useEffect(() => {
    setGroupedItems(
      faqsPaged &&
        faqsPaged.items.reduce((acc: any, item: any) => {
          const categoryName = item.properties.faqCategory[0].name;
          if (!acc[categoryName]) {
            acc[categoryName] = [];
          }
          acc[categoryName].push(item);
          return acc;
        }, {})
    );
  }, [faqsPaged]);

  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  function urlFor(source: any) {
    return baseUrl + source;
  }


  return (
    <div className={css.FAQ}>
      <Helmet>
      <title>{contents?.properties?.metaTitle ? contents?.properties?.metaTitle:" POND - Kilden til ditt nye familiemedlem"}</title>
        <meta name="description" content={contents?.properties?.metaDescription ? contents?.properties?.metaDescription : "Plattform for ansvarlig kjøp og salg av hund."} />
        <meta property="og:title" content={contents?.properties?.metaTitle ? contents?.properties?.metaTitle : " POND - Kilden til ditt nye familiemedlem"} />
        <meta property="og:type" content={contents?.properties?.metaKeywords ? contents?.properties?.metaKeyword : "website"} />
        <meta
          property="og:description"
          content={contents?.properties?.metaDescription ? contents?.properties?.metaDescription : "Plattform for ansvarlig kjøp og salg av hund."}
        />
        <link rel="canonical" href={contents?.properties?.canonicalUrl ? contents?.properties?.canonicalUrl:"https://pond-api.codify.no"}/>
        {contents?.properties?.ogImage && (
          <meta
            property="og:image"
            content={urlFor(contents?.properties?.ogImage[0].url).toString()}
          />)}
        </Helmet>
      {groupedItems && (
        <FaqSection
          items={groupedItems}
          setVisibleIndex={setVisibleIndex}
          visibleIndex={visibleIndex}
          css={css}
        />
      )}
    </div>
  );
};

export default FAQ;
