/* eslint-disable @typescript-eslint/no-explicit-any */
import useAuth0 from "../hooks/useAuth0";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { DogAd } from "../api/graphql.gen-old";
import Footer from "../components/Footer";
import css from "../styles/Home.module.scss";
import { useIsMobile } from "../hooks/useIsMobile";
import {
  ApiBlockItem,
  HjemContentModel,
  IApiContentResponsePagedViewModel,
} from "../api/umbracoApi";
import RenderHomePage from "../components/UmbracoComponents/Home/Index";
import {
  useUmbracoContentItemQuery,
  useUmbracoContentQuery,
} from "../hooks/useUmbracoQuery";
import { DogAdDto, useDogAdQueryQuery } from "../api/graphql.gen";
import { Helmet } from "react-helmet-async";

const Home: React.FC = () => {
  const history = useHistory();
  const [aboutPondSections, setAboutPondSections] = useState<any>();
  const [pondVisible, setPondVisible] = useState<boolean[]>([]);
  // const limitedSections = aboutPondSections.slice(0, 6);

  const { loginWithRedirect } = useAuth0();
  //const { data: allAds } = useGetAllAdsQuery();
       // const [articles, setArticles] = useState<any>();
      // const [sortedArticles, setSortedArticles] = useState<any>();
  const [randomAds, setRandomAds] = useState<DogAdDto[]>();
  const { isMobile } = useIsMobile(1024);
  const readMoreRef = useRef<HTMLDivElement>(null);
  const { data: contents } = useUmbracoContentItemQuery<any>(
    "/?expand=properties[pageSections[properties[testimonialPicker,articlePicker]]]"
  );
  const { data: Faq } =
    useUmbracoContentQuery<IApiContentResponsePagedViewModel>({
      filter: ["contentType:faqItem"],
    });
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
  //const [contents, setContents] = useState<any>([]); // Initialize as an empty array or appropriate type
  useEffect(() => {
    // window.analytics.page("Forside");

    // getFAQ();

    // client
    //   .fetch(`*[_type == "articles"] | order(date desc)`)
    //   .then((sanityArticles) => setArticles(sanityArticles));

    setAboutPondSections(Faq?.items);
  }, [Faq]);

            // useEffect(() => {
            // // if (articles) {
            // //   const publishedArticles = articles.filter((article: any) =>
            // //     isBefore(new Date(article.date), new Date())
            // //   );
            // //   setSortedArticles(publishedArticles.slice(0, 3));
            // // }
            // }, [articles]);

  //const { data: ads, isLoading } = useDogAdQueryQuery();

  // useEffect(() => {
  //   if (!ads || !ads.dogAdQuery) return; // Add this check
  //   const allAds = ads?.dogAdQuery?.dogAds;

  //   const filteredAds = allAds
  //     ?.filter((item) => item.adType === "AVAILABLE")
  //     .sort(() => 0.5 - Math.random())
  //     .slice(0, 3);
  //   setRandomAds(filteredAds as DogAdDto[]);
  // }, [ads]);

  // Image builder for sanity client: Generates url baset on image asset

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
            // function urlFor(source: any) {
            //   return "";
            // }

  useEffect(() => {
    //   const ads = allAds?.dogAds
    //     .filter((item) => item.adType === "AVAILABLE")
    //     .sort(() => 0.5 - Math.random())
    //     .slice(0, 3);
    //   setRandomAds(ads as DogAd[]);
    // }, [allAds]);
    // useEffect(() => {
    //   const script = document.createElement("script");
    //   script.innerHTML = `
    //   var BotStar = {
    //     appId: "s100789a0-cd84-11ed-917e-abdbde2d37a6",
    //     mode: "popup",
    //   };
    //   !(function (t, a) {
    //     var e = function () {
    //       (e.q = e.q || []).push(arguments);
    //     };
    //     (e.q = e.q || []), (t.BotStarApi = e);
    //     !(function () {
    //       var t = a.createElement("script");
    //       (t.type = "text/javascript"),
    //         (t.async = 1),
    //         (t.src = "https://widget.botstar.com/static/js/widget.js");
    //       var e = a.getElementsByTagName("script")[0];
    //       e.parentNode.insertBefore(t, e);
    //     })();
    //   })(window, document);`;
    //   script.async = true;
    //   document.body.appendChild(script);
    //   return () => {
    //     document.body.removeChild(script);
    //   };
  }, [window.location]);

  const executeScroll = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  };

  function urlFor(source: any) {
    return baseUrl + source;
  }
  return (
    <div className={css.Home}>
      <Helmet>
      <title>{contents?.properties?.metaTitle ? contents?.properties?.metaTitle:" POND - Kilden til ditt nye familiemedlem"}</title>
        <meta name="description" content={contents?.properties?.metaDescription ? contents?.properties?.metaDescription : "Plattform for ansvarlig kjøp og salg av hund."} />
        <meta property="og:title" content={contents?.properties?.metaTitle ? contents?.properties?.metaTitle : " POND - Kilden til ditt nye familiemedlem"} />
        <meta property="og:type" content={contents?.properties?.metaKeywords ? contents?.properties?.metaKeyword : "website"} />
        <meta
          property="og:description"
          content={contents?.properties?.metaDescription ? contents?.properties?.metaDescription : "Plattform for ansvarlig kjøp og salg av hund."}
        />
        <link rel="canonical" href={contents?.properties?.canonicalUrl ? contents?.properties?.canonicalUrl:"https://pond-api.codify.no"}/>
        {contents?.properties?.ogImage && (
          <meta
            property="og:image"
            content={urlFor(contents?.properties?.ogImage[0].url).toString()}
          />)}
        </Helmet>
      {contents?.properties?.pageSections?.items?.map((item: ApiBlockItem, index: number) => (
        <div key={index}>
          <RenderHomePage
            content={item.content}
            history={history}
            isMobile={isMobile}
            limitedSections={aboutPondSections}
            loginWithRedirect={loginWithRedirect}
            randomAds={randomAds}
            readMoreRef={readMoreRef} isAuthenticated={false} 
            />
        </div>
      ))}
      <Footer />
    </div>
  );
};

export default Home;
