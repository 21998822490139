import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import css from "../../styles/AboutPond.module.scss";
import communityCss from "../../styles/Community.module.scss";
import { Community } from "../../components/Community";
//import { SanityComponent, getPosts, Section } from "../../utils/sanity-common";
import {
  ApiBlockItem,
  HjemContentModel,
  IApiContentResponsePagedViewModel,
  OmOssContentModel,
  TextBackgroundPropertiesModel,
  TextWithDescriptionPropertiesModel,
} from "../../api/umbracoApi";
import { useUmbracoContentItemQuery, useUmbracoContentQuery } from "../../hooks/useUmbracoQuery";
import TextWithImage from "../../components/UmbracoComponents/About/TextWithImage";
import { useIsMobile } from "../../hooks/useIsMobile";
import TextWithButton from "../../components/UmbracoComponents/About/TextBackground";
import TextWithDescription from "../../components/UmbracoComponents/About/TextWithDescription";
import { isLeftHandSideExpression } from "typescript";
import { Helmet } from "react-helmet-async";

const AboutPond: React.FC = function () {
  const [teamModal, setTeamModal] = useState(false);
  const { data: contents } =
    useUmbracoContentItemQuery<any>("om-oss");

    const { data: communitySection } = useUmbracoContentItemQuery<HjemContentModel>(
      ""
   );
   console.log("ccommunitySection   : ",communitySection);

   const community: any=communitySection?.properties?.pageSections?.items.find(
      (item:any)=>{ 
        return item.content.contentType === "communitySection" ; 
      }
     );
     console.log("community  : ",community);
   
  const { isMobile } = useIsMobile();
  const { data: teamItems } =
  useUmbracoContentQuery<IApiContentResponsePagedViewModel>({
    filter: ["contentType:teamItem"],
  });
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  function urlFor(source: any) {
    return baseUrl + source;
  }

  return (
    <div className={css.AboutPond}>
      <Helmet>
      <title>{contents?.properties?.metaTitle ? contents?.properties?.metaTitle:" POND - Kilden til ditt nye familiemedlem"}</title>
        <meta name="description" content={contents?.properties?.metaDescription ? contents?.properties?.metaDescription : "Plattform for ansvarlig kjøp og salg av hund."} />
        <meta property="og:title" content={contents?.properties?.metaTitle ? contents?.properties?.metaTitle : " POND - Kilden til ditt nye familiemedlem"} />
        <meta property="og:type" content={contents?.properties?.metaKeywords ? contents?.properties?.metaKeyword : "website"} />
        <meta
          property="og:description"
          content={contents?.properties?.metaDescription ? contents?.properties?.metaDescription : "Plattform for ansvarlig kjøp og salg av hund."}
        />
        <link rel="canonical" href={contents?.properties?.canonicalUrl ? contents?.properties?.canonicalUrl:"https://pond-api.codify.no"}/>
        {contents?.properties?.ogImage && (
          <meta
            property="og:image"
            content={urlFor(contents?.properties?.ogImage[0].url).toString()}
          />)}
        </Helmet>
      <div className={css.infoPage}>
        {/* {sections?.map((section, index) => (
          <div key={index}>{SanityComponent(section)}</div>
        ))} */}

        {contents?.properties?.pageContent?.items?.map(
          (item: ApiBlockItem, index: number) => {
            switch (item.content.contentType) {
              case "textWithImage":
                return (
                  <TextWithImage
                    properties={item.content.properties}
                    isMobile={isMobile}
                  />
                );
              case "textBackground":
                return (
                  <TextWithButton
                    properties={
                      item.content.properties as TextBackgroundPropertiesModel
                    }
                  />
                );

              case "textWithDescription":
                return (
                  <TextWithDescription
                    properties={
                      item.content
                        .properties as TextWithDescriptionPropertiesModel
                    }
                  />
                );
              default:
                return (
                  <div key={index}>
                    <p>Unsupported content type: {item.content.contentType}</p>
                  </div>
                );
            }
            // <div key={item.id}>
            //   <RenderAboutPage content={item.content} isMobile={isMobile} />
            // </div>;
          }
        )}
      </div>
      <div className={css.content}>
        <div className={css.community}>
          {/* <h3>Vårt felleskap</h3> */}
          <h3>{community?.content?.properties?.title}</h3>
          <div className={communityCss.Community}>
            {community?.content?.properties?.communityList.items.map((item:any,index:any)=>{
                console.log(`${baseUrl}${item.content.properties.icon[0].url}?height=41&width=35`);
                return(
                  <div key={index} className={communityCss.gridItem}>
                <object type="image/svg+xml" data={baseUrl + item.content.properties.icon[0].url} >
                  {item.content.properties.title}
                </object>
                <div className={communityCss.bold}>{item.content.properties.rating}</div>
                <div>{item.content.properties.title}</div>
              </div>

                );
                

          })}
            

            
          </div>
          
          {/* <Community /> */}
        </div>
      </div>

      <Modal
        show={teamModal}
        onHide={() => setTeamModal(false)}
        fullscreen
        animation
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className={css.teamModal}>
            <h3>Møt teamet vårt!</h3>
            <div className={css.teamGrid}>
            {teamItems?.items.map((member:any) => (
                <div key={member.properties.personName} className={css.teamMember}>
                  <img src={baseUrl + member.properties.image[0].url} alt={member.properties.personName} />
                  <div className={css.name}>{member.properties.personName}</div>
                  <div>{member.properties.designation}</div>
                  <div>{member.properties.email ? member.properties.email : "-"}</div>
                </div>
              ))}
            </div>
            <Button label="Gå tilbake" onClick={() => setTeamModal(false)} />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AboutPond;
