import React from "react";
import { useHistory } from "react-router-dom";
import css from "../styles/NewAdSteps.module.scss";

const NewAdSteps: React.FC = () => {
  const history = useHistory();

  return (
    <div className={css.NewAdSteps}>
      <img
        src="/images/puppy-steps/born.png"
        alt="Født valpekull"
        onClick={() => history.push("/min-pond/mine-valper/ny/fodt")}
      />
      <img
        src="/images/puppy-steps/coming.png"
        alt="Kommende valpekull"
        onClick={() => history.push("/min-pond/mine-valper/ny/kommende")}
      />
      <img
        src="/images/puppy-steps/planned.png"
        alt="Planlagt valpekull"
        onClick={() => history.push("/min-pond/mine-valper/ny/planlagt")}
      />
    </div>
  );
};

export default NewAdSteps;
