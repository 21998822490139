import React, { useEffect } from "react";
import css from "../../styles/Info.module.scss";
import {
  useUmbracoContentItemQuery,
  useUmbracoContentQuery,
} from "../../hooks/useUmbracoQuery";
import { KontaktOssContentModel } from "../../api/umbracoApi";
import DOMPurify from "dompurify";
import { Helmet } from "react-helmet-async";

const ContactUs: React.FC = function () {
  const { data: contents } =
    useUmbracoContentItemQuery<any>("kontakt-oss");

  useEffect(() => {
    window.analytics.page("Kontakt oss");
  }, []);

  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  function urlFor(source: any) {
    return baseUrl + source;
  }


  let iframeHtml = `${contents?.properties?.contactForm}`;
  iframeHtml = iframeHtml.replace("##className##", css.responsiveIframe);
  return (
    <div className={css.infoPage}>
      <Helmet>
      <title>{contents?.properties?.metaTitle ? contents?.properties?.metaTitle:" POND - Kilden til ditt nye familiemedlem"}</title>
        <meta name="description" content={contents?.properties?.metaDescription ? contents?.properties?.metaDescription : "Plattform for ansvarlig kjøp og salg av hund."} />
        <meta property="og:title" content={contents?.properties?.metaTitle ? contents?.properties?.metaTitle : " POND - Kilden til ditt nye familiemedlem"} />
        <meta property="og:type" content={contents?.properties?.metaKeywords ? contents?.properties?.metaKeyword : "website"} />
        <meta
          property="og:description"
          content={contents?.properties?.metaDescription ? contents?.properties?.metaDescription : "Plattform for ansvarlig kjøp og salg av hund."}
        />
        <link rel="canonical" href={contents?.properties?.canonicalUrl ? contents?.properties?.canonicalUrl:"https://pond-api.codify.no"}/>
        {contents?.properties?.ogImage && (
          <meta
            property="og:image"
            content={urlFor(contents?.properties?.ogImage[0].url).toString()}
          />)}
        </Helmet>
      <h4>{contents?.properties?.title}</h4>
      <h5>{contents?.properties?.subTitle}</h5>
      <p>{contents?.properties?.description}</p>
      <div
        className={css.iframeContainer}
        dangerouslySetInnerHTML={{ __html: iframeHtml }}
      ></div>
    </div>
  );
};
export default ContactUs;
