import React from "react";
import css from "./../styles/Articles.module.scss";
import ArticleCard from "../components/ArticleCard";
import { useIsMobile } from "../hooks/useIsMobile";

import {
  ArticleItemContentModel,
  ArtiklerContentModel,
} from "../api/umbracoApi";
import {
  useUmbracoContentItemQuery,
  useUmbracoContentQuery,
} from "../hooks/useUmbracoQuery";
import { CircularProgress, Stack } from "@mui/material";
import { Helmet } from "react-helmet-async";

const Articles: React.FC = () => {
  const { isMobile } = useIsMobile(1024);
  const { data: contents } = useUmbracoContentItemQuery<any>("artikler");
  const { data: articlePaged, isLoading } = useUmbracoContentQuery<any>({
    fetch: "children:artikler",
    sort: ["createDate:desc"],
  });
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const articles = articlePaged?.items;

  const renderArticles = (articles: any) => {
    if (isLoading) {
      return (
        <Stack alignItems="center" mt="32px">
          <CircularProgress color="primary" variant="indeterminate" />
        </Stack>
      );
    }
    return (
      <div className={css.content}>
        <div className={css.breeders}>
          <div className={css.title}>{contents?.properties?.title}</div>
          {articles && articles.length > 1 && (
            <ArticleCard
              title={articles[0].properties?.heading}
              url={articles[0].route.path}
              articleType={
                (articles[0].properties?.articalCategory &&
                  articles[0].properties?.articalCategory[0].name) ||
                ""
              }
              img={
                articles[0].properties?.image
                  ? articles[0].properties?.image[0].url +
                    "?width=455&height=400"
                  : ""
              }
              breed={articles[0].properties?.shortDescription ?? ""}
              date={new Date(articles[0].properties?.articleDate)}
              mobile={isMobile}
            />
          )}

          <div className={isMobile ? undefined : css.cardGrid}>
            {articles?.map(
              (article: ArticleItemContentModel, index: number) => {
                return index === 0 ? undefined : (
                  <ArticleCard
                    key={article.id}
                    title={article.properties?.heading}
                    url={article.route.path}
                    articleType={
                      (article.properties?.articalCategory &&
                        article.properties?.articalCategory[0].name) ||
                      ""
                    }
                    img={
                      article.properties?.image
                        ? article.properties?.image[0].url +
                          "?width=375&height=200"
                        : ""
                    }
                    breed={article.properties?.shortDescription ?? ""}
                    date={new Date(article.properties?.articleDate ?? "")}
                    mobile={true}
                  />
                );
              }
            )}
          </div>
        </div>
      </div>
    );
  };

  function urlFor(source: any) {
    return baseUrl + source;
  }

  return (
    articles && (
      <div className={css.articles}>
        <Helmet>
          <title>
            {contents?.properties?.metaTitle
              ? contents?.properties?.metaTitle
              : " POND - Kilden til ditt nye familiemedlem"}
          </title>
          <meta
            name="description"
            content={
              contents?.properties?.metaDescription
                ? contents?.properties?.metaDescription
                : "Plattform for ansvarlig kjøp og salg av hund."
            }
          />
          <meta
            property="og:title"
            content={
              contents?.properties?.metaTitle
                ? contents?.properties?.metaTitle
                : " POND - Kilden til ditt nye familiemedlem"
            }
          />
          <meta
            property="og:type"
            content={
              contents?.properties?.metaKeywords
                ? contents?.properties?.metaKeyword
                : "website"
            }
          />
          <meta
            property="og:description"
            content={
              contents?.properties?.metaDescription
                ? contents?.properties?.metaDescription
                : "Plattform for ansvarlig kjøp og salg av hund."
            }
          />
          <link
            rel="canonical"
            href={
              contents?.properties?.canonicalUrl
                ? contents?.properties?.canonicalUrl
                : "https://pond-api.codify.no"
            }
          />
          {contents?.properties?.ogImage && (
            <meta
              property="og:image"
              content={urlFor(contents?.properties?.ogImage[0].url).toString()}
            />
          )}
        </Helmet>
        <div className={css.intro}>
          <h3>{contents?.properties?.title}</h3>
          <p>{contents?.properties?.description}</p>
        </div>
        {renderArticles(articles)}
      </div>
    )
  );
};

export default Articles;
