/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import css from "../styles/Article.module.scss";
import { Avatar } from "@mui/material";
import Button from "../components/Button";
import { useHistory, useParams } from "react-router-dom";
import { ArrowBackIos } from "@mui/icons-material";
import useAuth0 from "../hooks/useAuth0";
import { Helmet } from "react-helmet-async";
import {
  useUmbracoContentItemQuery,
  useUmbracoContentQuery,
} from "../hooks/useUmbracoQuery";
import {
  ApiBlockItem,
  ArticleItemContentModel,
  ArtiklerContentModel,
  IApiContentResponsePagedViewModel,
} from "../api/umbracoApi";
import DOMPurify from "dompurify";
import RenderArticlePage from "../components/UmbracoComponents/Article/Index";

interface RouteParams {
  slug: string;
}

const Article: React.FC = () => {
  const { slug } = useParams<RouteParams>();
  const { data: article } = useUmbracoContentItemQuery<any>(`artikler/${slug}`);

  const { data: contents } =
    useUmbracoContentItemQuery<ArtiklerContentModel>("artikler");

  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  async function getPosts() {
    // const sanityArticles = await client.fetch(`*[slug.current == "${slug}"]`);
    // setArtciles(sanityArticles);
  }

  const history = useHistory();

  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    getPosts();
  }, []);

  if (article) {
    return (
      <div className={css.article}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{article.name ?? "POND"}</title>
          <meta property="og:title" content={article.name ?? ""}></meta>
          <meta
            property="og:description"
            content={article?.properties?.description?.markup ?? ""}
          ></meta>
          {/* <meta
            property="og:image"
            content={urlFor(articles[0]?.coverImage).url()}
          ></meta> */}
          <meta property="og:type" content="article"></meta>
          <meta
            property="og:url"
            content={`http://pond.no/artikler/${slug}`}
          ></meta>
        </Helmet>
        <div
          className={css.backButton}
          onClick={() => history.push("/artikler")}
        >
          <ArrowBackIos /> Tilbake til oversikt
        </div>
        <div className={css.blockContainer}>
          <h6>
            {article.properties?.breederText}{" "}
            {article.properties?.shortDescription}
          </h6>
          <h1>{article.properties?.heading}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                article.properties?.description?.markup ?? ""
              ),
            }}
          />
        </div>
        <img
          src={
            (article.properties?.image &&
              baseUrl + article.properties.image[0].url + "?width=1920") ||
            undefined
          }
          alt="Banner bilde"
          className={css.bannerImage}
        />
        {isAuthenticated ? (
          <span>
            {article?.properties?.articlePageContent?.items?.map(
              (item: ApiBlockItem, index: number) => (
                <div key={index}>
                  {
                    <RenderArticlePage
                      content={item.content}
                      baseUrl={baseUrl}
                    />
                  }
                </div>
              )
            )}
          </span>
        ) : (
          <div className={css.notLoggedIn}>
            <p>{contents?.properties?.loginHeading}</p>
            <Button
              label={
                contents?.properties?.loginRedirect
                  ? (contents?.properties?.loginRedirect[0].title as string)
                  : ""
              }
              onClick={() => {
                history.push(
                  `${
                    contents?.properties?.loginRedirect?.[0]?.route &&
                    contents.properties.loginRedirect[0].route.path
                  }`
                );
              }}
            />
          </div>
        )}
      </div>
    );
  }
  return <></>;
};

export default Article;
