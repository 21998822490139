import React, { useEffect } from "react";
import css from "../../styles/Info.module.scss";
import DOMPurify from "dompurify";
import {
  useUmbracoContentItemQuery,
} from "../../hooks/useUmbracoQuery";
import {
  TextPageContentModel,
} from "../../api/umbracoApi";

const TermsOfUse: React.FC = function () {
 const { data: contents } = useUmbracoContentItemQuery<TextPageContentModel>(
    "betingelser-og-vilkar"
  );
  useEffect(() => {
    window.analytics.page("Brukervilkår for POND");
  }, []);
  const sanitizedContent = DOMPurify.sanitize(
    contents?.properties?.pageBody?.markup ?? ""
  );
  return (
    <div className={css.infoPage}>
      <h1>{contents?.properties?.pageTitle}</h1>
      <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
    </div>
  );
};

export default TermsOfUse;
