import * as React from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "../../../../components/Button";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useOnBoardingContext } from "../../../../context/OnBoardingContext";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import useAuth from "../../../../hooks/useAuth0";
import css from "../../../../styles/Steps.module.scss";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface IProps {
  show: boolean;
  onSuccess: (step: number, data: any) => void;
}

const Step2: React.FC<IProps> = ({ show, onSuccess }) => {
  const { user } = useAuth();
  const { breeds, clubs, agents } = useOnBoardingContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({});
  const [club, setClub] = React.useState<boolean>(false);

  const isBreeder = user?.breeder;
  const nkkid = watch("NKKId");
  React.useEffect(() => {
    if (!nkkid) {
      setClub(false);
      setValue("AgriaAgentId", "");
      setValue("JoinAgria", false);
    }
  }, [nkkid]);

  React.useEffect(() => {
    setValue("AgriaAgentId", "");
    setValue("JoinAgria", false);
  }, [club]);

  const onSubmit = (data: any) => {
    if (isBreeder) {
      onSuccess(2, { ...data, AgriaMember: club, isBreeder: isBreeder });
    } else {
      onSuccess(3, { ...data, isBreeder: isBreeder });
    }
  };
  return (
    <Stack style={{ display: show ? "flex" : "none" }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isBreeder ? (
          <Stack gap={4} maxWidth={{ sm: 564, xs: 345 }} className={css.step1}>
            <Stack direction="row" gap={"22px"}>
              <TextField
                label="Kennelnavn*"
                variant="outlined"
                fullWidth
                error={!!errors["KennelName"]}
                {...register("KennelName", {
                  required: "Kennelnavn må oppgis.",
                  pattern: {
                    value: /^[A-Za-zæøåÆØÅ\s@]{2,50}$/,
                    message:
                      "KennelName må være mellom 2 og 50 tegn og kan kun inneholde bokstaver og mellomrom",
                  },
                })}
                helperText={errors["KennelName"]?.message}
              />
              <TextField
                label="NKK-medlemsnummer"
                variant="outlined"
                fullWidth
                error={!!errors["NKKId"]}
                {...register("NKKId")}
              />
            </Stack>
            <Controller
              render={({ field }) => (
                <Autocomplete
                  multiple
                  fullWidth
                  options={breeds?.data || []}
                  renderOption={(props, option: any, { selected }) => {
                    return (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.text as string}
                      </li>
                    );
                  }}
                  getOptionLabel={(option: any) => option?.text}
                  renderInput={(params) => (
                    <TextField
                      error={!!errors.Breeds}
                      {...params}
                      label="Hvilken rase avler du?*"
                      helperText={errors["Breeds"]?.message}
                    />
                  )}
                  onChange={(_, newValue) => field.onChange(newValue)}
                />
              )}
              name="Breeds"
              control={control}
              rules={{ required: "Velg minst en rase." }}
            />
            <Controller
              render={({ field }) => (
                <Autocomplete
                  fullWidth
                  options={clubs?.data || []}
                  renderOption={(props, option: any) => {
                    return <li {...props}>{option.text as string}</li>;
                  }}
                  getOptionLabel={(option: any) => option?.text}
                  renderInput={(params) => (
                    <TextField {...params} label="Raseklubb" />
                  )}
                  onChange={(_, newValue) => field.onChange(newValue.text)}
                />
              )}
              name="Clubs"
              control={control}
            />
            {nkkid && (
              <Stack direction="row" gap={"20px"} maxWidth={"332px"}>
                <Box>
                  <img src="/images/breeders-club.png" />
                </Box>
                <Stack>
                  <h6
                    style={{
                      height: "48px",
                      fontWeight: 400,
                      color: "#065C53",
                    }}
                  >
                    Er du medlem i Agria Breeders Club?
                  </h6>
                  <FormGroup sx={{ flexDirection: "row" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!club}
                          onChange={() => setClub(true)}
                        />
                      }
                      label="Ja"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={club === false}
                          onChange={() => setClub(false)}
                        />
                      }
                      label="Nei"
                    />
                  </FormGroup>
                </Stack>
              </Stack>
            )}
            {club === true && (
              <Controller
                render={({ field, fieldState }) => (
                  <FormControl fullWidth>
                    <InputLabel id="agents-select-label">
                      Hvem er din rådgiver?
                    </InputLabel>
                    <Select
                      {...field}
                      labelId="agents-select-label"
                      label="Hvem er din rådgiver?"
                      error={!!fieldState.error}
                    >
                      {agents.data.map((agent: any) => (
                        <MenuItem key={agent.id} value={agent.id}>
                          {agent.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                name="AgriaAgentId"
                control={control}
              />
            )}
            {nkkid && club === false && (
              <Stack
                sx={{
                  color: "#065C53",
                  h6: { marginBottom: 2 },
                  li: { marginBottom: "8px" },
                }}
              >
                <h6>
                  Det er gratis å være medlem,{" "}
                  <span style={{ fontWeight: 400 }}>
                    og du får fordeler som:
                  </span>
                </h6>
                <ul>
                  <li>Valpepakker til kjøperne dine</li>
                  <li>
                    Poeng du kan bruke til å kjøpe flotte produkter i Agria
                    Breeders Shop
                  </li>
                  <li>Nyhetsbrev om hund & helse</li>
                </ul>
                <FormControlLabel
                  sx={{
                    alignItems: "flex-start",
                    ".MuiTypography-root": { fontSize: "16px" },
                    ".MuiCheckbox-root": { paddingTop: "0px" },
                  }}
                  control={
                    <Controller
                      render={({ field, fieldState }) => (
                        <Checkbox
                          {...field}
                          checked={field.value}
                          sx={{
                            "&.MuiCheckbox-root": {
                              color: fieldState.error ? "#d32f2f" : "initial",
                            },
                          }}
                        />
                      )}
                      name="JoinAgria"
                      control={control}
                      rules={{ required: false }}
                    />
                  }
                  label="Kryss av om du ønsker å bli medlem og kontaktet av Agria breeders club. Du trenger ikke å være forsikret i Agria. Ved å krysse av samtykker du for at din kontaktinformasjon blir sendt til Agria Breeders Club."
                />
              </Stack>
            )}
            <Stack alignItems="center">
              <Button
                height="56px"
                label="Fortsett"
                skin="dark"
                style={{ width: "100%" }}
                onClick={() => handleSubmit(onSubmit)}
              />
            </Stack>
          </Stack>
        ) : (
          <Stack gap={4} maxWidth={564}>
            <p>
              Legg til hunderasene du er interessert i så vil du bli varslet når
              oppdrettere har ledige valper av de valgte rasene.
            </p>
            <Controller
              render={({ field }) => (
                <Autocomplete
                  multiple
                  fullWidth
                  options={breeds?.data || []}
                  renderOption={(props, option, { selected }) => {
                    return (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.text}
                      </li>
                    );
                  }}
                  getOptionLabel={(option: any) => option?.text}
                  renderInput={(params) => (
                    <TextField
                      error={!!errors.Breeds}
                      {...params}
                      label="Hvilke raser er du interessert i?*"
                      helperText={errors["Breeds"]?.message}
                    />
                  )}
                  onChange={(_, newValue) => field.onChange(newValue)}
                />
              )}
              name="Breeds"
              control={control}
              rules={{ required: "Velg minst en rase." }}
            />{" "}
            <Stack alignItems="center">
              <Button
                height="56px"
                label="Fortsett"
                skin="dark"
                style={{ width: "100%" }}
                onClick={() => handleSubmit(onSubmit)}
              />
            </Stack>
          </Stack>
        )}
      </form>
    </Stack>
  );
};

export default Step2;
