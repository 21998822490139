import React, { useEffect, useRef, useState } from "react";
import css from "../../styles/EditProfile.module.scss";
import DashboardBanner from "../../components/Dashboard/DashboardBanner";
import DashboardBreadcrumbs from "../../components/Dashboard/DashboardBreadcrumbs";
import { useIsMobile } from "../../hooks/useIsMobile";
import {
  Alert,
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Button as ButtonSubmit,
} from "@mui/material";
import Button from "../../components/Button";
import Input from "../../components/NewInput";
import Footer from "./Footer";
import useAuth0 from "../../hooks/useAuth0";
import { useHistory } from "react-router-dom";
import {
  useGetKennelQuery,
  useUpdateKennelMutation,
} from "../../api/graphql.gen";
import { useUmbracoContentQuery } from "../../hooks/useUmbracoQuery";
import { IApiContentResponsePagedViewModel } from "../../api/umbracoApi";
import { deleteFile, uploadFile } from "../../api/services/file";
import { FileType } from "../../enums";
import { CircularProgress, Stack } from "@mui/material";
import { axios } from "../../api/authenticated-axios";

const EditProfile: React.FC = () => {
  const { isMobile } = useIsMobile();
  const { isAuthenticated, isLoading } = useAuth0();
  const { data: kennelData } = useGetKennelQuery();
  const { data: breedsData } =
    useUmbracoContentQuery<IApiContentResponsePagedViewModel>({
      filter: ["contentType:dogBreedItem"],
    });
  const { data: clubData } =
    useUmbracoContentQuery<IApiContentResponsePagedViewModel>({
      filter: ["contentType:clubItem"],
    });
  const [AgriaAgents, setAgriaAgentsInfo] = useState<any>();

  const kennel = kennelData?.kennelQuery.me;
  const allBreeds = breedsData?.items.map((x) => x.name);
  const clubNames = clubData?.items.map((x) => x.name);
  const history = useHistory();

  const [kennelName, setKennelName] = useState("");
  const [NKKMember, setNKKMember] = useState(false);
  const [savedChanges, setSavedChanges] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [joinAgria, setJoinAgria] = useState(false);
  const [hasClub, setHasClub] = useState(false);
  const [NKKMembership, setNKKMembership] = useState("");
  const [breeds, setBreeds] = useState<string[]>([""]);
  const [about, setAbout] = useState<string>("");
  const [clubs, setClubs] = useState<string[]>([""]);
  const [profilePicture, setProfilePicture] = useState<string>(
    "/images/default-avatar.png"
  );
  const [position, setPosition] = useState<string>("");
  const [ownerActivity, setOwnerActivity] = useState<number>();
  const [ownerFamily, setOwnerFamily] = useState<number>();
  const [ownerLiving, setOwnerLiving] = useState<number>();
  const [ownerExperience, setOwnerExperience] = useState<number>();
  const [yearsOfExperience, setYearsOfExperience] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const [bannerImageUrl, setBannerImageUrl] = useState("");
  const [bannerText, setBannerText] = useState("");
  const [selectedImage, setSelectedImage] = useState(0);
  const [myAgent, setMyAgent] = useState<string>("");
  const [isAgriaMember, setIsAgriaMember] = useState<boolean>();
  const [isImageRemoved, setIsImageRemoved] = useState(false);
  const [clubError, setClubError] = useState<string>("");
  const [agriaError, setAgriaError] = useState<string>("");

  const { mutate: updateProfile, isLoading: isUpdating } =
    useUpdateKennelMutation({
      onSuccess: () => setSavedChanges(true),
      onError: () => setApiError(true),
    });

  useEffect(() => {
    const getAgriaAgents = async () => {
      const result = await axios.get("/register/getagriaagents");
      setAgriaAgentsInfo(result.data);
    };
    getAgriaAgents();
  }, []);

  const saveChanges = async () => {
    const agriaAgentId =
      AgriaAgents?.find((agent: any) => agent.name === myAgent)?.id ?? "";

    const media = await uploadFileAsync();

    if (
      hasClub &&
      (!clubs ||
        clubs.length === 0 ||
        clubs.every((club) => !club || !club.trim()))
    ) {
      setClubError("This field is required.");
      return;
    } else {
      setClubError("");
    }

    if (isAgriaMember && (!agriaAgentId || agriaAgentId === "0")) {
      setAgriaError("This field is required.");
      return;
    } else {
      setAgriaError("");
    }

    kennel?.id &&
      updateProfile({
        model: {
          id: kennel.id,
          name: kennelName,
          about,
          nkkId: NKKMember ? NKKMembership : "",
          nkkCheck: NKKMember,
          breeds,
          bannerImage: media ?? "",
          bannerText: bannerText,
          clubs: hasClub ? clubs : [],
          url: "",
          experience: about,
          motivation: about,
          specialization: about,
          clubPosition: position,
          ownerActivity,
          ownerFamily,
          ownerExperience,
          ownerLiving,
          firstYear: parseInt(yearsOfExperience),
          profilePicture:
            profilePicture === "/images/default-avatar.png"
              ? null
              : profilePicture,
          agriaAgentId: isAgriaMember ? agriaAgentId?.toString() : "",
          agriaMember: isAgriaMember,
          joinAgria: joinAgria,
        },
      });
  };

  const updateBreeds = (value: string, index: number) => {
    const li: string[] = [];
    breeds.map((item, i) => (i === index ? li.push(value) : li.push(item)));
    setBreeds(li);
  };

  useEffect(() => {
    if (kennel) {
      if (AgriaAgents?.length) {
        const agentName = AgriaAgents.find(
          (agent: any) => agent.id.toString() === kennel?.agriaAgentId
        )?.name;
        setMyAgent(agentName || "");
      }
      setKennelName(kennel?.name ?? "");
      setNKKMembership(kennel?.nkkId ?? "");
      setNKKMember(kennel?.nkkCheck ?? false);
      setBreeds(kennel?.breedersList ?? [""]);
      setAbout(kennel?.motivation ?? "");
      setClubs(kennel?.clubsList?.length ? kennel?.clubsList : [""]);
      setHasClub(!!kennel?.clubs?.length);
      setYearsOfExperience(kennel?.firstYear?.toString() ?? "");
      setBannerImage(kennel?.bannerImage ?? "");
      setBannerText(
        kennel?.fileRecord?.url
          ? `${process.env.REACT_APP_API_BASE_URL}${kennel?.fileRecord?.url}`
          : ""
      );
      setBannerImageUrl(
        kennel?.fileRecord?.url
          ? `${process.env.REACT_APP_API_BASE_URL}${kennel?.fileRecord?.url}`
          : ""
      );
      setOwnerActivity(kennel?.ownerActivity ?? undefined);
      setOwnerExperience(kennel?.ownerExperience ?? undefined);
      setOwnerFamily(kennel?.ownerFamily ?? undefined);
      setOwnerLiving(kennel?.ownerLiving ?? undefined);
      setPosition(kennel?.clubPosition ?? "");
      setProfilePicture(profilePicture ?? "/images/default-avatar.png");
      setIsAgriaMember(kennel.isAgriaMember ?? false);
    }
  }, [kennel, AgriaAgents]);

  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [fileSelected, setFileSelected] = useState<File | null>(null);

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setBannerImageUrl(previewUrl);
      setFileSelected(file);
      setIsImageRemoved(false);
    }
  };

  const clearFile = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.value = "";
    }
    setBannerImageUrl("");
    setFileSelected(null);
    setIsImageRemoved(true);
  };

  const createMediaJson = (media: any) => {
    if (!media) return null;
    const file = {
      id: media.mediaId,
      url: media.mediaUrl,
    };
    return JSON.stringify(file);
  };

  const uploadFileAsync = async () => {
    if (!fileSelected && isImageRemoved && kennel?.fileRecord?.id) {
      await deleteFile(parseInt(kennel?.fileRecord?.id?.toString() ?? ""));
      return null;
    }

    if (!fileSelected) {
      const fileResult = {
        mediaId: kennel?.fileRecord?.id,
        mediaKey: kennel?.fileRecord?.id,
        mediaUrl: kennel?.fileRecord?.url,
      };

      return createMediaJson(fileResult);
    }

    const fileResult = await uploadFile(
      fileSelected,
      FileType.Kennel,
      kennel?.fileRecord?.id?.toString()
    );
    setFileSelected(null);
    return createMediaJson(fileResult);
  };

  useEffect(() => {
    window.analytics.page("Rediger profil");
  }, []);

  return (
    <div className={css.EditProfile}>
      {!isMobile && (
        <DashboardBreadcrumbs
          prev="POND / Rediger profil"
          next="Kontoinnstillinger >"
        />
      )}
      <div className={css.contentContainer}>
        <DashboardBanner
          image="/images/icons/dashboard/edit-circle.svg"
          percentage={60}
          title="Rediger profil"
          info="POND råder til å følge NKK og raseklubbene sine retningslinjer for avl av hund. Vi anbefaler å følge disse. Falske opplysninger vil kunne føre til utestengelse. "
          customWidth={""}
        />
        {isLoading ? (
          <Stack alignItems="center" mt="32px">
            <CircularProgress color="primary" variant="indeterminate" />
          </Stack>
        ) : (
          <>
            <div className={css.mainContainer}>
              <div className={css.leftContainer}>&nbsp; </div>
              <div className={css.infoContainer}>
                <div className={css.inputContainer}>
                  <Input
                    id="kennelName"
                    placeholder="Navn på kennel"
                    value={kennelName}
                    required
                    onChange={(value) => setKennelName(value)}
                    type="text"
                    className={css.input}
                  />
                </div>
                <FormGroup className={css.check}>
                  <FormControlLabel
                    sx={{
                      ".MuiTypography-root": {
                        width: "70vw",
                      },
                    }}
                    control={
                      <Checkbox
                        checked={NKKMember}
                        onClick={() => {
                          setNKKMember(!NKKMember);
                        }}
                      />
                    }
                    label="Medlem av NKK"
                  />
                </FormGroup>
                {NKKMember && (
                  <div className={css.inputContainer}>
                    <Input
                      id="NKKMembership"
                      placeholder="NKK Medlemsnummer"
                      value={NKKMembership}
                      onChange={(value) => setNKKMembership(value)}
                      type="text"
                      className={css.input}
                    />
                  </div>
                )}
                <div className={css.breedContainer}>
                  {breeds.map((_item, index) => (
                    <FormControl
                      key={`breed-${index}`}
                      className={css.breedInputContainer}
                    >
                      <InputLabel>Hunderaser</InputLabel>
                      <Select
                        name={`breed${index}`}
                        id={`breed${index}`}
                        className={css.inputSelect}
                        value={breeds[index]}
                        onChange={(e) =>
                          updateBreeds(e.target.value as string, index)
                        }
                        required
                      >
                        {allBreeds?.map((breed, i) => (
                          <MenuItem
                            sx={{ zIndex: 100000 }}
                            value={breed || ""}
                            key={i}
                          >
                            {breed}
                          </MenuItem>
                        ))}
                      </Select>
                      <Button
                        label="Slett"
                        skin="noColor"
                        className={css.deleteButton}
                        onClick={() => {
                          const arr: string[] = [];
                          breeds.map((breed, i) => {
                            if (index !== i) {
                              arr.push(breed);
                            }
                          });
                          setBreeds(arr);
                        }}
                      />
                    </FormControl>
                  ))}
                  <div
                    className={css.addMore}
                    onClick={() => setBreeds([...breeds, ""])}
                  >
                    + Legg til rase
                  </div>
                </div>
                <div className={css.inputContainer}>
                  <Input
                    id="experienceYears"
                    placeholder="Fortell om kennelen din"
                    value={about}
                    onChange={(value) => setAbout(value)}
                    type="text"
                    multiline
                    className={css.input}
                  />
                </div>
                <div className={css.inputContainer}>
                  <Input
                    id="experienceYears"
                    placeholder="Første kull (år)"
                    value={yearsOfExperience}
                    onChange={(value) => setYearsOfExperience(value)}
                    type="text"
                    className={css.input}
                    error={
                      parseInt(yearsOfExperience) > new Date().getFullYear()
                    }
                    errorMessage={
                      parseInt(yearsOfExperience) > new Date().getFullYear()
                        ? "Året kan ikke være frem i tid"
                        : ""
                    }
                  />
                </div>
                <FormGroup className={css.check2}>
                  <FormControlLabel
                    sx={{
                      ".MuiTypography-root": {
                        width: "70vw",
                      },
                    }}
                    control={
                      <Checkbox
                        checked={hasClub}
                        onChange={() => setHasClub(!hasClub)}
                      />
                    }
                    label="Jeg er med i kennel klubb"
                  />
                </FormGroup>
                {hasClub && (
                  <div>
                    {clubs.map((club, index) => (
                      <div
                        className={css.inputContainer}
                        key={club + "-" + index}
                      >
                        <div style={{ display: "flex" }}>
                          <Autocomplete
                            value={club}
                            options={clubNames || []}
                            onChange={(e, value) => {
                              const arr: string[] = [];
                              clubs.map((item, i) => {
                                if (i === index) {
                                  arr.push(value as string);
                                } else {
                                  arr.push(item);
                                }
                              });
                              setClubs(arr);
                              if (arr.length > 0) setClubError("");
                            }}
                            className={css.input}
                            renderInput={(params) => (
                              <TextField {...params} label="Navn på klubb" />
                            )}
                          />
                          <Button
                            skin="noColor"
                            label="Slett"
                            onClick={() => {
                              const remainingClubs = clubs.filter(
                                (item) => item !== clubs[index]
                              );
                              setClubs(remainingClubs);
                            }}
                          />
                        </div>
                      </div>
                    ))}
                    <div className={css.btnss}>
                      <Button
                        skin="noColor"
                        label="+ Legg til flere"
                        onClick={() => setClubs([...clubs, ""])}
                      />
                    </div>
                  </div>
                )}
                {clubError && <p style={{ color: "red" }}>{clubError}</p>}
                <div className={css.inputContainer}>
                  <div className={css.title}>Bannerbilde</div>
                  <div className={css.bannerImageGrid}>
                    <input
                      type="file"
                      hidden
                      ref={hiddenFileInput}
                      onChange={onFileChange}
                    />
                    {bannerImageUrl ? (
                      <div className={css.existingBanner}>
                        <img
                          src={bannerImageUrl}
                          onClick={() => {
                            setSelectedImage(1);
                            hiddenFileInput.current &&
                              hiddenFileInput.current.click();
                          }}
                        />
                        <p style={{ cursor: "pointer" }} onClick={clearFile}>
                          Slett bannerbilde
                        </p>
                      </div>
                    ) : (
                      <div
                        className={css.existingBanner}
                        onClick={() => {
                          setSelectedImage(1);
                          hiddenFileInput.current &&
                            hiddenFileInput.current.click();
                        }}
                      >
                        <img src="/images/icons/image.svg" />
                        <div>Legg til bilde</div>
                      </div>
                    )}
                  </div>
                </div>
                {NKKMembership && NKKMembership !== "" && (
                  <div className={css.agriaSection}>
                    <div className={css.heading}>
                      <img
                        className={css.logo}
                        src="/images/agria-logo.png"
                        alt="Agria breeders club logo"
                      />
                      <div>Er du medlem i Agria Breeders Club?</div>
                    </div>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={!!isAgriaMember}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          onChange={() => setIsAgriaMember(true)}
                          label="Ja"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          onChange={() => {
                            setIsAgriaMember(false);
                          }}
                          label="Nei"
                        />
                      </RadioGroup>
                    </FormControl>
                    {isAgriaMember && (
                      <div>
                        <h4>Hvem er din rådgiver?</h4>
                        <TextField
                          name="myAgent"
                          select
                          id="myAgent"
                          value={myAgent || ""}
                          fullWidth
                          onChange={(e) => setMyAgent(e.target.value)}
                          placeholder="Rådgiver"
                        >
                          <MenuItem value="Velg rådgiver">
                            Velg rådgiver
                          </MenuItem>
                          {AgriaAgents?.map((agent: any, i: any) => (
                            <MenuItem value={agent.name || ""} key={i}>
                              {agent.name}
                            </MenuItem>
                          ))}
                        </TextField>
                        <p>
                          Husker du ikke hvem din rådgiver er? Send mail til{" "}
                          <a href="mailto:info@agriabreedersclub.no">
                            info@agriabreedersclub.no
                          </a>
                        </p>
                      </div>
                    )}
                    {agriaError && <p style={{ color: "red" }}>{agriaError}</p>}
                    {isAgriaMember === false && isAgriaMember !== undefined && (
                      <div>
                        <h4>Ønsker du å bli medlem?</h4>
                        <p>Som medlem får du fordeler som:</p>
                        <ul>
                          <li>Valpepakker til kjøperne dine</li>
                          <li>
                            Poeng du kan bruke til å kjøpe flotte produkter i
                            Agria Breeders Shop
                          </li>
                          <li>Nyhetsbrev om hund & helse</li>
                        </ul>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={() => setJoinAgria(!joinAgria)}
                            />
                          }
                          label="Det er gratis å være medlem, og du trenger ikke å være forsikret i Agria. Kryss av om du ønsker å bli kontaktet av Agria breeders club"
                        />
                        <p>
                          Ved å krysse av samtykker du for at din
                          kontaktinformasjon blir sendt til Agria Breeders Club
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div>
              <Button
                label="Tilbake"
                skin="lightGreen"
                disabled={isLoading}
                onClick={() => history.goBack()}
              />
              {/* <Button label="Fullfør" skin="dark" onClick={saveChanges} /> */}
              {/* <Button
                height="56px"
                disabled={isLoading}
                label={
                  isLoading ? (
                    <CircularProgress
                      color="secondary"
                      variant="indeterminate"
                      size={20}
                    />
                  ) : (
                    "Fullfør"
                  )
                }
                skin={isLoading ? "light" : "dark"}
                style={{ width: 168 }}
                onClick={saveChanges}
              /> */}
              <ButtonSubmit
                disabled={isUpdating}
                variant="contained"
                size="large"
                style={{ width: "10rem" }}
                // type="submit"
                onClick={saveChanges}
              >
                {isUpdating ? (
                  <CircularProgress color="primary" size={24} />
                ) : (
                  "Fullfør"
                )}
              </ButtonSubmit>
            </div>
          </>
        )}

        {savedChanges && (
          <Alert
            severity="success"
            onClose={() => setSavedChanges(false)}
            className={css.success}
          >
            Dine endringer har blitt lagret
          </Alert>
        )}
        {apiError && (
          <Alert
            severity="error"
            onClose={() => setApiError(false)}
            className={css.error}
          >
            Det oppstod en feil ved lagring av endringer
          </Alert>
        )}
        <Footer />
      </div>
    </div>
  );
};

export default EditProfile;
