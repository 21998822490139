import useAuth0 from "../../hooks/useAuth0";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import DashboardBanner from "../../components/Dashboard/DashboardBanner";
import DashboardBreadcrumbs from "../../components/Dashboard/DashboardBreadcrumbs";
import { useIsMobile } from "../../hooks/useIsMobile";
import css from "../../styles/Favorites.module.scss";
import { getBlobSrc } from "../../utils/getBreedImage";
import Footer from "./Footer";
import { useGetMeQueryQuery } from "../../api/graphql.gen";
import { CircularProgress, Stack } from "@mui/material";
import { useUmbracoContentQuery } from "../../hooks/useUmbracoQuery";
import { IApiContentResponsePagedViewModel } from "../../api/umbracoApi";
import DOMPurify from "dompurify";

const Favorites: React.FC = () => {
  const { isMobile } = useIsMobile();
  const { isAuthenticated, isLoading } = useAuth0();
  const { data: userData } = useGetMeQueryQuery();
  const user = userData?.memberQuery.me;
  // const breedList = user?.favorites?.breedersList;

  // let sanitizedBreedList = DOMPurify.sanitize(breedList?.toString() ?? "");

  // sanitizedBreedList = sanitizedBreedList.replace(/\s/g, "&nbsp;");

  // const data = useUmbracoContentQuery<IApiContentResponsePagedViewModel>({
  //   filter: [`contentType:dogBreedItem&filter=name:${sanitizedBreedList}`],
  // });
  // console.log(data, sanitizedBreedList);
  const history = useHistory();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      history.push("/");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    window.analytics.page("Favoritter");
  }, []);

  function convertToEnglishUrl(text: string) {
    return text
      .toLowerCase()
      .replace(/ä/g, "ae")
      .replace(/å/g, "aa")
      .replace(/ø/g, "oe")
      .replace(/æ/g, "ae")
      .replace(/ /g, "-");
  }

  return (
    <div className={css.Favorites}>
      {!isMobile && (
        <DashboardBreadcrumbs prev="POND / Kontoinnstillinger" next="  >" />
      )}
      <div className={css.contentContainer}>
        <DashboardBanner
          image="/images/icons/dashboard/paw-circle.svg"
          percentage={60}
          title="Favoritter"
          info="Her kan du se alle rasene du har lagret"
          customWidth={""}
        />
        {isLoading ? (
          <Stack alignItems="center" mt="32px">
            <CircularProgress color="primary" variant="indeterminate" />
          </Stack>
        ) : (
          <div className={css.breedGrid}>
            {user?.favorites?.breedersList?.map((breed: string) => (
              <div
                key={breed}
                className={css.breedContainer}
                onClick={() =>
                  history.push(`/hunderaser/${convertToEnglishUrl(breed)}`)
                }
              >
                <img src={getBlobSrc(breed)} alt={breed} />
                <h4>{breed}</h4>
              </div>
            ))}
          </div>
        )}
        {!isLoading && !user?.favorites?.breedersList?.length && (
          <div>Du har ingen favoritter enda</div>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default Favorites;
