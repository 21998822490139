import * as React from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "../../../../components/Button";
import UploadButton from "../../../../components/UploadButton";
import { Autocomplete, Checkbox, CircularProgress, FormControlLabel } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useOnBoardingContext } from "../../../../context/OnBoardingContext";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DatePickerProvider from "../../../../components/DatePickerProvider";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import css from '../../../../styles/Steps.module.scss'

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

interface IProps {
    show: boolean
    onSuccess: (step: number, data: any)=> void
    isLoading: boolean
}

const Step3: React.FC<IProps> = ({show, onSuccess, isLoading}) => {
    const { tests } = useOnBoardingContext();
    const { register, handleSubmit, formState: { errors }, control } = useForm();

    const onSubmit = (data: any)=> {
        onSuccess(3, data);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{display: show? "block": "none"}}>
            <Stack gap={4}  maxWidth={{sm:564, xs:345}} paddingBottom={{xs:'16px'}}>
                <h6 style={{ fontWeight: 400, color: "#065C53", marginBottom: "0px" }}>
                    Dette er din mulighet til å vise frem kennelen din! Du kan alltid
                    redigere og oppdatere informasjonen senere.
                </h6>
                <Stack>
                    <h6 style={{ fontWeight: 600, color: "#065C53" }}>
                        Last opp profilbilde
                    </h6>
                    <Controller
                        render={({ field })=> (
                            <UploadButton accept="image/*" onChange={(file: any)=> field.onChange(file)}
                                sx={{
                                    backgroundColor: "secondary.main",
                                    "&:hover": {
                                        backgroundColor: "secondary.main",
                                    },
                                    h6: {
                                        color: "#065C53",
                                    },
                                    p: {
                                        color: "#555555",
                                    },
                                    height: "200px",
                                }}
                            />
                        )}
                        name='ProfilePicture'
                        control={control}
                    />
                    
                </Stack>
                <Controller
                    render={({ field })=> (
                        <DatePickerProvider>
                            <DatePicker label="Når fikk du ditt første kull?" 
                            sx={{flex: 1}} views={["year"]} onChange={(c: dayjs.Dayjs | null)=> field.onChange(c?.format("YYYY"))} 
                                />
                        </DatePickerProvider>
                    )}
                    name="FirstYear"
                    control={control}
                />
                <TextField
                    label="Gi en beskrivelse av din kennel"
                    multiline
                    rows={4}
                    variant="outlined"
                    fullWidth
                    {...register("About", {maxLength:{ value:1000, message:"Beskrivelse må ikke være mer enn 1000 tegn."}  })}
                />
                <Controller
                    render={({field}) => (
                        <Autocomplete
                            multiple
                            fullWidth
                            options={tests?.data || []}
                            renderOption={(props, option, { selected }) => {
                                return (
                                    <li {...props}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.text}
                                    </li>
                                );
                            }}
                            getOptionLabel={(option: any) => option?.text}
                            renderInput={params => <TextField error={!!errors.MedicalTests} {...params} label='Hvilke helsetester tar du?' helperText={errors.MedicalTests?.message} />}
                            onChange={(_, newValue) => field.onChange(newValue)}
                        />
                    )}
                    name='MedicalTests'
                    control={control}
                    
                />
                 <Stack>
                    <h6 style={{ fontWeight: 600, color: "#065C53" }}>
                        Last opp bannerbilde
                    </h6>
                <Controller
                    render={({ field })=> (
                        <UploadButton accept="image/*" onChange={(file: any)=> {field.onChange(file)}}
                            sx={{
                                backgroundColor: "secondary.main",
                                "&:hover": {
                                    backgroundColor: "secondary.main",
                                },
                                h6: {
                                    color: "#065C53",
                                },
                                p: {
                                    color: "#555555",
                                },
                                height: "200px",
                            }}
                        />
                    )}
                    name='BannerImage'
                    control={control}
                />
                </Stack>
                <Stack alignItems="center">
                    <Button height="56px" label={isLoading? <CircularProgress color="primary" variant="indeterminate" />:"Fortsett"} skin={isLoading? "light": "dark"} style={{ width: '100%' }} onClick={()=> !isLoading && handleSubmit(onSubmit)} />
                </Stack>
            </Stack>
        </form>
    );
};

export default Step3;
