import React, { useState, useEffect } from "react";
import css from "../styles/BreedOverview.module.scss";
import "rc-slider/assets/index.css";
import { Chip, TextField } from "@mui/material";
import { Search, Tune } from "@mui/icons-material";
import SearchFilter from "../components/SearchFilter";
import NoResult from "../components/NoResult";
import { useIsMobile } from "../hooks/useIsMobile";
import { Link, useHistory } from "react-router-dom";
import LoadingScreen from "../components/LoadingScreen";
import Fade from "react-reveal/Fade";
import {
  useUmbracoContentQuery,
  useUmbracoContentItemQuery,
} from "../hooks/useUmbracoQuery";
import {
  HunderaserContentModel,
  IApiContentResponsePagedViewModel,
} from "../api/umbracoApi";
import { Helmet } from "react-helmet-async";
//Filter query:
//filter=contentType:dogBreedItem&filter=breedersize:Liten,Stor&filter=breederactivity:Lav&filter=breedercoop:Mye
//&filter=breederfurcare:Lav,Mye&filter=name:afg
const BreedOverview: React.FC = () => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const history = useHistory();
  const [searchInput, setSearchInput] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [selectedSize, setSelectedSize] = useState<string[]>([]);
  const [selectedActivity, setSelectedActivity] = useState<string[]>([]);
  const [filterOptions, setFilterOptions] = useState<string[]>([]);
  
  const [selectedCoop, setSelectedCoop] = useState<string[]>([]);
  const [selectedFurCare, setSelectedFurCare] = useState<string[]>([]);
   
  const [defaultQuery, setDefaultQuery] = useState<string[]>([
    "contentType:dogBreedItem",
  ]);
  const { data: searchBreeds, isLoading } =
    useUmbracoContentQuery<IApiContentResponsePagedViewModel>({
      filter: defaultQuery,
      sort: ["name:asc"],
    });
  const { data: pagedSize } =
    useUmbracoContentQuery<IApiContentResponsePagedViewModel>({
      filter: ["contentType:stRrelseItem"],
    });

  const { data: pagedActivity } =
    useUmbracoContentQuery<IApiContentResponsePagedViewModel>({
      filter: ["contentType:aktivitetsnivItem"],
    });

  const { data: pagedCoop } =
    useUmbracoContentQuery<IApiContentResponsePagedViewModel>({
      filter: ["contentType:samarbeidItem"],
    });

  const { data: pagedFurCare } =
    useUmbracoContentQuery<IApiContentResponsePagedViewModel>({
      filter: ["contentType:pelspleieItem"],
    });

  const { data: contents } =
    useUmbracoContentItemQuery<any>("hunderaser");

  const breeds = searchBreeds?.items;
  const sizeList = pagedSize?.items;
  const activityList = pagedActivity?.items
  const coopList = pagedCoop?.items
  const furCareList = pagedFurCare?.items

  useEffect(() => {
    const filterParameters: string[] = ["contentType:dogBreedItem"];
    const params = new URLSearchParams();
    if (searchInput.trim() !== "") {
      params.set("searchInput", searchInput);
      filterParameters.push(makeFilter("name:", [searchInput]));
    } else {
      params.delete("searchInput");
    }
    if (selectedSize.length > 0) {
      params.set("size", selectedSize.join(","));
      filterParameters.push(makeFilter("breedersize:", selectedSize));
    }
    if (selectedActivity.length > 0) {
      params.set("activity", selectedActivity.join(","));
      filterParameters.push(makeFilter("breederactivity:", selectedActivity));
    }
    if (selectedCoop.length > 0) {
      params.set("activity", selectedCoop.join(","));
      filterParameters.push(makeFilter("breedercoop:", selectedCoop));
    }

    if (selectedFurCare.length > 0) {
      params.set("activity", selectedFurCare.join(","));
      filterParameters.push(makeFilter("breederfurcare:", selectedFurCare));
    }
   
    setDefaultQuery(filterParameters);
  }, [selectedSize, selectedActivity,selectedCoop, selectedFurCare,  searchInput]);

  const makeFilter = (filterType: string, filter: string[]) => {
    let finalFilter = "";
    if (filter.length > 0) {
      finalFilter = `${filterType}${filter.join(",")}`;
    }
    return finalFilter;
  };

  const capitalize = (str: string) =>
    str.charAt(0).toUpperCase() + str.slice(1);

  useEffect(() => {
    window.analytics.page("Raseoversikt");
  }, []);

  const { isMobile } = useIsMobile(1024);

  function urlFor(source: any) {
    return baseUrl + source;
  }

  
  return (
    <div className={css.BreedOverview}>
      <Helmet>
      <title>{contents?.properties?.metaTitle ? contents?.properties?.metaTitle:" POND - Kilden til ditt nye familiemedlem"}</title>
        <meta name="description" content={contents?.properties?.metaDescription ? contents?.properties?.metaDescription : "Plattform for ansvarlig kjøp og salg av hund."} />
        <meta property="og:title" content={contents?.properties?.metaTitle ? contents?.properties?.metaTitle : " POND - Kilden til ditt nye familiemedlem"} />
        <meta property="og:type" content={contents?.properties?.metaKeywords ? contents?.properties?.metaKeyword : "website"} />
        <meta
          property="og:description"
          content={contents?.properties?.metaDescription ? contents?.properties?.metaDescription : "Plattform for ansvarlig kjøp og salg av hund."}
        />
        <link rel="canonical" href={contents?.properties?.canonicalUrl ? contents?.properties?.canonicalUrl:"https://pond-api.codify.no"}/>
        {contents?.properties?.ogImage && (
          <meta
            property="og:image"
            content={urlFor(contents?.properties?.ogImage[0].url).toString()}
          />)}
        </Helmet>
      <div className={css.intro}>
        <div className={css.content}>
          <h1>{contents?.properties?.title}</h1>
          <p>{contents?.properties?.description}</p>
        </div>
      </div>
      <div className={css.searchResult}>
        {!showFilter && (
          <div className={css.searchContainer}>
            <div className={css.horizontalContainer}>
              {isMobile && (
                <Tune
                  onClick={() => setShowFilter(true)}
                  className={css.filterButton}
                />
              )}
              <TextField
                className={css.searchBar}
                fullWidth={false}
                label={
                  <span className={css.inputLabel}>
                    <Search />
                    Søk etter rase
                  </span>
                }
                value={searchInput}
                onChange={(e) => setSearchInput(e.currentTarget.value)}
                style={{ background: "#fff", width: "100%" }}
              />
            </div>
          </div>
        )}
        <div className={css.container}>
          {!isMobile && (
            <SearchFilter
              selectSize={(size) => setSelectedSize(size)}
              selectedSizes={selectedSize}
              sizeData={sizeList}
              selectActivity={(activity) => setSelectedActivity(activity)}
              selectedActivities={selectedActivity}
              activityData={activityList}

              selectCoop={(coop) => setSelectedCoop(coop)}
              selectedCoops={selectedCoop}
              coopData={coopList}

              selectFurCare={(furCare) => setSelectedFurCare(furCare)}
              selectedFurCares={selectedFurCare}
              furCareData={furCareList}


              setFilterOptions={(option) => setFilterOptions(option)}
              filterOptions={filterOptions}
             
            />
          )}
          {isMobile && showFilter ? (
            <SearchFilter
              onClose={() => setShowFilter(false)}
              selectSize={(size) => setSelectedSize(size)}
              selectedSizes={selectedSize}
              sizeData={sizeList}
              selectActivity={(activity) => setSelectedActivity(activity)}
              selectedActivities={selectedActivity}
              activityData={activityList}
              selectCoop={(coop) => setSelectedCoop(coop)}
              selectedCoops={selectedCoop}
              coopData={coopList}

              selectFurCare={(furCare) => setSelectedFurCare(furCare)}
              selectedFurCares={selectedFurCare}
              furCareData={furCareList}
              setFilterOptions={(size) => setFilterOptions(size)}
              filterOptions={filterOptions}
              
            />
          ) : isLoading ? (
            <LoadingScreen />
          ) : breeds?.length === 0 ? (
            <NoResult />
          ) : (
            <div>
              <Fade>
                <div className={css.chipContainer}>
                  {filterOptions.map((item) => (
                    <Chip
                      className={css.chip}
                      label={item}
                      key={item}
                      color="primary"
                    />
                  ))}
                </div>
                <div className={css.breedGrid}>
                  {breeds &&
                    breeds?.map((breed: any, index: number) => {
                      const profilePhoto =
                        breed.properties?.profilePhoto?.length > 0
                          ? baseUrl +
                            breed.properties?.profilePhoto[0]?.url +
                            "?width=310&height=310"
                          : "";

                      return (
                        <Link
                          className={css.item}
                          key={index}
                          to={`${breed?.route?.path}`}
                        >
                          <img
                            src={profilePhoto}
                            alt={capitalize(breed?.properties?.breedName)}
                            onError={(e) => {
                              e.currentTarget.src = "/images/breeds/pond.png";
                            }}
                            className={css.blob}
                          />
                          <h4>{capitalize(breed?.properties?.breedName)}</h4>
                        </Link>
                      );
                    })}
                </div>
              </Fade>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BreedOverview;
